<template>
  <form class="block" @submit.prevent="handleSubmit">
    <h4>Вход</h4>
    <input type="text" placeholder="Username" v-model="username" />
    <input type="password" placeholder="Password" v-model="password" />
    <div class="error" v-if="errorLogin">{{ errorLogin }}</div>
    <div class="login-btn">
      <button :disabled="showLoader">Вход</button>
      <LoaderCircular v-if="showLoader" size="34px" />
    </div>
  </form>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import LoaderCircular from "@/components/LoaderCircular";
import firebaseCredential from "@/composables/firebaseCredential";
import useLogin from "@/composables/useLogin";
export default {
  components: {
    LoaderCircular,
  },
  setup() {
    const router = useRouter();
    const { login, errorLogin } = useLogin();
    const { user } = firebaseCredential();
    const username = ref("");
    const password = ref("");
    const showLoader = ref(false);
    const handleSubmit = async () => {
      showLoader.value = true;
      errorLogin.value = null;
      await login(username.value, password.value);
      showLoader.value = false;
      router.push({ name: "Home" });
    };
    const handleOnMounted = async () => {
      if (user.value != null) {
        router.push({ name: "Home" });
      }
    };
    return {
      handleSubmit,
      username,
      password,
      showLoader,
      errorLogin,
      handleOnMounted,
    };
  },
  mounted() {
    this.handleOnMounted();
  },
};
</script>

<style>
.login-btn {
  display: flex;
}
.login-btn button {
  width: 33%;
  margin-right: 4px;
}
</style>
