import { ref } from "vue";
import { projectAuth } from "@/firebase/config";

import useApi from "@/composables/useApi";

const errorLogin = ref(null);
const { firebaseCustomToken } = useApi();

const login = async (username, password) => {
  try {
    let token = await firebaseCustomToken(username, password);
    return await projectAuth.signInWithCustomToken(token);
  } catch (error) {
    if (error.message.indexOf("401") > 0) {
      errorLogin.value = `Неправильное имя или пароль`;
    } else {
      errorLogin.value = `Ошибка входа: ${error.message}`;
    }
  }
};

const useLogin = () => {
  return { login, errorLogin };
};

export default useLogin;
